/* main */
#main-filter-view label,
#main-display-settings label {
  cursor: pointer;
}
#main-filter-view input[type="radio"],
#main-display-settings input[type="radio"] {
  display: none;
}
#main-filter-view input[type="radio"]:checked + svg,
#main-display-settings input[type="radio"]:checked + svg {
  fill: #007bff;
}
#main-filter-view input[type="radio"]:checked ~ span {
  color: #007bff;
}
.btn-property-expand[aria-expanded="true"] {
  background-color: #007bff;
}

#propertyViewTabs.active {
  background-color: black;
}
.tbl-space-list {
  width: 100%;
  table-layout: fixed;
}
.tbl-space-list-ten {
  width: 10%;
}
.tbl-space-list-twenty {
  width: 20%;
}

.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#myGrid {
  flex: 1 1 0px;
  width: 100%;
}

.tab-nested-grid:hover {
  background: transparent;
}

/* .full-width-panel {
  position: relative;
  background: #edf6ff;
  height: 100%;
  width: 100%;
  padding: 5px;
}

.call-record-cell {
  text-align: right;
}

.full-width-detail {
  padding-top: 4px;
}

.full-width-details {
  float: left;
  padding: 5px;
  margin: 5px;
  width: 150px;
}

.full-width-grid {
  margin-left: 150px;
  padding-top: 25px;
  display: block;
  height: 100%;
}

.full-width-grid-toolbar {
  top: 4px;
  left: 30px;
  margin-left: 150px;
  display: block;
  position: absolute;
}

.full-width-phone-icon {
  padding-right: 10px;
}

.full-width-search {
  border: 1px solid #eee;
  margin-left: 10px;
}

.ag-react-container {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.my-green-class {
  background-color: green;
} */

/*
#property-action-bar > p,
#property-action-bar > div {
  padding-left: 16px;
}
*/
.table-standard-compare-bar {
  padding-bottom: 20px;
  padding-left: 10px;
}
.table-standard-compare-bar > th {
  border-bottom: 0;
}
.table-standard-compare-icon {
  margin-left: -50%;
  display: inline-block;
  padding: 1em;
  padding-bottom: 0.5em;
  background: #bbb;
  border-radius: 50%;
  color: #fff;
}
.table-standard-compare-icon svg {
  fill: #fff;
}
.table-standard-compare-bar-subcolumn {
  display: flex;
  align-items: center;
}
.table-standard-compare-bar p {
  font-size: 1rem;
}
.table-standard-details-tabcontent-compare-container {
  border-right: 1px solid #ccc;
}
.table-standard-details-tabcontent-compare-container .ag-center-cols-container {
  width: 100% !important;
}
.ag-theme-alpine .ag-ltr .ag-cell.column-compare {
  border-right: 1px solid #ccc;
}
.details-cell,
.main-details-cell {
  font-weight: bold;
}
.main-details-cell-extra {
  color: #999;
  /*font-size: 1.2em;*/
}
.table-main-details-summary .ag-header-cell-text {
  color: #999;
}
.table-main-details-summary .ag-cell-value {
  background-color: #f1f5fd;
  font-size: 2.4rem;
}
.table-main-details-summary .ag-cell-value:first-child {
  /*font-size: 1.2em;*/
  font-weight: bold;
}
.accordion.accordion-container:first-child,
.accordion.accordion-container:last-child {
  border-radius: 0;
  border-bottom: 1px solid #f1f5fd;
}
.accordion.accordion-container-overview:first-of-type {
  margin: 10px 0;
}
.accordion.accordion-container-overview {
  margin: 10px 0;
  background: var(--neutral-white);
  border: 1px solid var(--neutral-grey-150);
  border-radius: 6px;
  box-shadow: none;
}
.accordion.accordion-container-overview .MuiAccordionSummary-root {
  min-height: auto;
}
.accordion.accordion-container-overview .MuiAccordionSummary-content {
  margin: 14px 0;
}
.accordion.accordion-container-stacking-plan {
    background: var(--neutral-white);
    border: 1px solid var(--neutral-grey-150);
    border-radius: 6px;
    box-shadow: none;
}
.summary-table-stacking-plan-container {
    box-shadow: none;
}
.accordion .accordion-details {
  padding: 0;
}
.accordion.accordion-container-leasing:first-of-type {
  margin: 0 0 20px 0;
}
.accordion.accordion-container-leasing:last-of-type {
  margin-bottom: 10px;
}
.accordion-details-leasing-activity .accordion.accordion-container-leasing:first-of-type {
  margin-top: 20px;
}
.accordion-details-leasing-activity .accordion.accordion-container-leasing:last-of-type {
  margin-bottom: 20px;
}
.accordion.accordion-container-leasing {
  margin: 0 0 20px 0;
  background: var(--neutral-white);
  border: 1px solid var(--neutral-grey-150);
  border-radius: 6px;
  box-shadow: none;
}
.accordion.accordion-container-leasing::before {
  background-color: transparent;
}
/*
.accordion-summary-leasing-activity {
  border: 1px solid var(--neutral-grey-150);
}
*/
.accordion.accordion-container-leasing:last-child > .MuiCollapse-root {
  border-top: 1px solid var(--neutral-grey-150);
}
.accordion.accordion-container-leasing .MuiAccordionSummary-root {
  min-height: auto;
}
.accordion.accordion-container-leasing .MuiAccordionSummary-content {
  margin: 14px 0;
}
.accordion.accordion-container-leasing.accordion-container-leasing-sub {
  margin-right: 13px;
  margin-left: 13px;
}
.accordion.accordion-container-leasing.accordion-container-leasing-sub-alt {
  background: var(--neutral-grey-50);
}
.accordion.accordion-container-leasing.accordion-container-leasing-sub-pipeline:last-child, .accordion.accordion-container-leasing.accordion-container-leasing-sub-pipeline:nth-child(8) {
  background: var(--neutral-grey-50);
}
.accordion.accordion-container-leasing.accordion-container-leasing-sub-pipeline:last-child .MuiCollapse-root, .accordion.accordion-container-leasing.accordion-container-leasing-sub-pipeline:nth-child(8) .MuiCollapse-root {
  background: var(--neutral-white);
}
.accordion-summary-stacking-plan .MuiAccordionSummary-expandIconWrapper svg {
  height: 24px;
  fill: var(--primary-dark-blue);
}
.summary-table-stacking-plan-title-bar {
  display: flex;
  align-items: center;
}
.summary-table-stacking-plan-icon-container {
  display: inline-block;
  padding: 10px 10px 6px 10px;
  background: rgba(6, 108, 250, 0.08);
  border-radius: 6px;
}
.summary-table-stacking-plan-icon-container svg {
  width: 22px;
  height: 22px;
  color: var(--primary-blue);
}
.summary-table-stacking-plan .col-head-stacking-plan-key {
  padding: 8px 0;
  border-bottom: 5px solid;
  white-space: nowrap;
}
.summary-table-stacking-plan th {
  width: 10%;
}
.summary-table-stacking-plan th:first-child {
  width: 20%;
}
.summary-table-stacking-plan tbody td {
  padding: 12px 0;
  white-space: nowrap;
}
.summary-table-stacking-plan tbody td:first-child {
  padding: 12px 16px;
  font-weight: bold;
}
.summary-table-stacking-plan-building-title-bar {
  margin-top: 20px;
  padding: 24px 20px 16px 20px;
  border-top: 1px solid var(--neutral-grey-150);
}
.accordion-details-stacking-plan
  .summary-table-stacking-plan
  tbody
  tr:last-child
  td {
  border-bottom: 0;
}
/*
.stacking-plan-floor-container {
  display: flex;
  align-items: stretch;
}
*/
#property-stackingplan-content {
  padding: 0px 20px 20px 20px;
  background-color: var(--neutral-grey-50);
}
.label-stacking-plan-floor-container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
.label-stacking-plan-floor-name {
  padding-left: 20px;
  /*
  font-weight: bold;
  font-size: 1.2em;
  text-align: right;*/
}
.label-stacking-plan-floor-area {
  padding-left: 20px;
  /*
  color: #666;
  text-align: right;*/
}
.accordion-details-stacking-plan .label-stacking-plan-space-container {
  margin-right: 2px;
  display: inline-block;
  overflow: hidden;
}
.accordion-details-stacking-plan
  .label-stacking-plan-space-container:last-child {
  margin-right: 0;
}
.accordion
  .accordion-details-stacking-plan
  .stacking-plan-floor-container-outer {
  padding: 0 4px 2px 4px;
}
.accordion-details-stacking-plan .label-stacking-plan-space-container-inner {
  display: flex;
  flex-flow: column wrap;
  padding: 10px;
  border-radius: 5px;
  box-shadow: none;
  width: 100%;
}
.label-stacking-plan-space-tenant {
  padding-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: bold;
}
.label-stacking-plan-space-tenant a {
  color: var(--primary-dark-blue);
  text-decoration: none;
}
.label-stacking-plan-space-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.label-stacking-plan-space-details-container svg {
  width: 13px;
  min-width: 13px;
  height: 13px;
  min-height: 13px;
  color: var(--primary-dark-blue);
}
.label-stacking-plan-space-expire {
  /*display: flex;
  flex-basis: 50%;*/
  flex-grow: 999;
  flex-shrink: 1;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.label-stacking-plan-space-area {
  /*display: flex;
  flex-basis: 50%;*/
  flex-grow: 1;
  flex-shrink: 99999;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.label-stacking-plan-space-leaseid {
  width: 100%;
}
.summary-table-stacking-plan-building-container
  .summary-table-stacking-plan-container {
  box-shadow: none;
  border: 0;
}

.popover-stacking-plan::before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(46, 57, 72, 0.3);
  border-bottom: 10px solid transparent;
  right: 50%;
  bottom: -6px;
}

.popover-stacking-plan::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  border-bottom: 10px solid transparent;
  right: 50%;
  bottom: -6px;
}

.popover-stacking-plan {
  z-index: 1300;
}
.popover-stacking-plan .MuiPaper-elevation {
  border-radius: 6px;
}
.popover-stacking-plan-table {
  border-bottom: 0;
}
.popover-stacking-plan-table-header:first-child th {
  padding-bottom: 0;
}
.popover-stacking-plan-table-header:last-child th {
  padding-top: 0;
}
.popover-stacking-plan-table-header-title {
  font-weight: bold;
  text-decoration: underline;
}
.popover-stacking-plan-table-header-expires svg {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}
.popover-stacking-plan-table-body td {
  border: 1px solid var(--neutral-grey-150);
  border-width: 0 1px 1px 0;
}
.popover-stacking-plan-table-body td:first-child {
  border-width: 0 0 1px 1px;
}

.table-leasing-pipeline-comparison th {
  font-weight: bold;
}
.table-leasing-pipeline-comparison thead th {
  padding-top: 0;
  padding-bottom: 5px;
}
.table-leasing-pipeline-comparison td:nth-child(5) {
  border-left: 1px solid rgba(224, 224, 224, 1);
}
.table-leasing-pipeline-comparison tbody td:nth-child(5),
.table-leasing-pipeline-comparison tbody td:nth-child(6) {
  color: #727272;
}
.tab-pane-no-summary {
  display: flex;
  flex-flow: wrap;
}
#summaryOptions > div {
  display: flex;
  flex-flow: wrap;
  /*padding-bottom: 1em;*/
}
@media (min-width: 768px) {
  /*
  .label-stacking-plan-space-details-container {
    flex-flow: nowrap;
  }
  .label-stacking-plan-space-expire {
    padding-right: 20px;
    width: auto;
    white-space: nowrap;
  }
  .label-stacking-plan-space-area {
    width: auto;
    white-space: nowrap;
  }*/
  .label-stacking-plan-space-leaseid {
    width: 100%;
    text-align: right;
  }
}
@media (min-width: 1280px) {
  .details-nav-tabs-container {
    max-width: 60%;
    border-right: 1px solid #ccc;
  }
  .table-standard-details-tabcontent-compare-container {
    width: 60% !important;
  }
  #tab-pane {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  /*
  #property-action-bar {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: absolute;
    margin-top: 2rem;
    width: 240px;
    background: #fff;
  }
  */
  #property-action-bar.property-leasingactivity-action-bar {
    width: 250px;
  }
  /*
  #property-action-bar.propertylist-action-bar {
    margin-top: 0.5rem;
    width: 220px;
  }
  */
  #property-action-bar.property-action-bar-no-summary {
    margin-top: 0;
    width: 220px;
  }
  #summaryOptions {
    margin-left: 250px;
  }
  #property-action-bar.property-action-bar-no-summary + #summaryOptions {
    margin-left: 230px;
  }
  /*
  #property-action-bar > p,
  #property-action-bar > div {
    width: calc(50% - 20px);
  }
  */
  #property-action-bar > p {
    padding-top: 4rem;
    padding-left: 20px;
    /*font-size: 14px;*/
  }
  .tab-content {
    width: 100%;
  }
}

.table-standard-title-bar {
  padding-top: 20px;
  padding-bottom: 10px;
}
.table-standard-title-bar h6 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.table-standard-export-container {
  padding-right: 20px;
  text-align: right;
}
button[aria-label="warning"] svg {
  fill: #ff9800;
}
@media (min-width: 1280px) {
  .table-standard-title-bar {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .table-standard-title-bar h6 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.table-standard-toggle-container .ag-theme-alpine .ag-root-wrapper {
  border-width: 0;
  border-top-width: 1px;
}
.table-standard-toggle-container .ag-theme-alpine .ag-status-bar {
  border-top: 0;
}

.subcell-data {
  font-size: 1rem;
  color: #727272;
}

/* overview */
#property-details-content {
  background: #f8fafe;
}
#property-details-content .property-details-content-header {
  padding: 8px 16px;
  background: #303f9f;
  box-shadow: 0px 0px 3px #00000076;
  font-weight: bold;
  /*font-size: 16px;*/
  line-height: 1.2;
  color: #fff;
}
#property-details-content table {
  border-bottom: 1px solid #ddd;
}
#property-details-content .table-container:last-child table {
  margin-bottom: 0;
}
#property-details-content th {
  border: 0;
  font-weight: 700;
  /*font-size: 15px;*/
  line-height: 1.2;
}
#property-details-content th:nth-child(2) {
  text-align: right;
}
#property-details-content td {
  padding: 0 0.75em;
  /*width: 50%;*/
  border: 0;
  color: #727272;
}
#property-details-content td:nth-child(2) {
  text-align: right;
  padding-right: 3em;
}
#property-details-content .property-details-content-table-address td {
  width: auto;
}
#property-details-content tr:last-child td {
  padding-bottom: 0.75em;
}
#property-details-content .btn-primary {
  box-shadow: 0px 0px 5px #00000076;
}
#property-details-content .btn-primary svg {
  margin-top: -4px;
  margin-left: 8px;
  font-size: 1rem;
}
.asset-details-address {
  margin-bottom: 0;
}
#property-details-widgets {
  padding: 10px 20px;
  background-color: var(--neutral-grey-50);
}
.property-details-widgets-date {
  padding-bottom: 2em;
}
.widget-container {
  padding-bottom: 2em;
}
.widget-container .widget-card {
  padding: 1em;
  box-shadow: 0px 0px 10px #00000029;
}
.widget-compare-amounts p {
  /*font-size: 14px;*/
  line-height: 1.2;
  color: #727272;
}
.widget-compare-amounts h2 {
  font-size: 3rem;
  line-height: 1.5;
}
.widget-compare-amounts .widget-compare-text {
  font-size: 1.1rem;
  line-height: 1.5;
}
.widget-amounts {
  background: transparent linear-gradient(270deg, #43a047 0%, #225024 100%);
}
.widget-amounts-income {
  background: transparent linear-gradient(270deg, #6559cc 0%, #3b3572 100%);
}
.widget-amounts-cashflow {
  background: transparent linear-gradient(270deg, #4350e0 0%, #242ead 100%);
}
.widget-amounts-expenses {
  background: transparent linear-gradient(270deg, #f27417 0%, #aa612a 100%);
}
.widget-amounts p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.2;
  color: #fff;
}
.widget-amounts h2 {
  font-size: 3rem;
  line-height: 1.5;
  color: #fff;
}
.widget-amounts .icon-dollar {
  float: right;
  display: block;
  width: 1.7em;
  height: 1.7em;
  border: 3px solid rgba(255, 255, 255, 0.77);
  border-radius: 50%;
  font-size: 2rem;
  line-height: 1.5;
  color: #ffffffdb;
  text-align: center;
}
.widget-card h3 {
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.2;
}
.widget-variance-summary-column-header {
  text-align: right !important;
}
.table-variance-summary th {
  /* text-align: right; */
  padding: 0 1em 0.25em 1em;
  border: 0;
  font-weight: bold;
  /*font-size: 14px;*/
  line-height: 1.2;
  color: #191919;
}
.table-variance-summary td {
  text-align: right;
  padding: 0 1em 0.25em 1em;
  border: 0;
  font-weight: bold;
  /*font-size: 14px;*/
  line-height: 1.2;
  color: #191919;
}
.table-variance-summary thead th {
  padding: 0 1em 1em 1em;
  border: 0;
}
.widget-variance-summary-section-header {
  text-transform: uppercase;
}
.table-variance-summary td {
  font-weight: normal;
  color: #727272;
}
.table-variance-summary tr:last-child th,
.table-variance-summary tr:last-child td {
  padding-bottom: 1em;
}
.table-variance-summary th:nth-child(2),
.table-variance-summary th:nth-child(3),
.table-variance-summary th:nth-child(5),
.table-variance-summary th:nth-child(7),
.table-variance-summary thead:first-child th:nth-child(4),
.table-variance-summary td:nth-child(2),
.table-variance-summary td:nth-child(3),
.table-variance-summary td:nth-child(5),
.table-variance-summary td:nth-child(7) {
  padding-left: 3em;
  border-left: 1px solid #ddd;
}
.table-variance-summary th:nth-child(2),
.table-variance-summary th:nth-child(4),
.table-variance-summary th:nth-child(6),
.table-variance-summary td:nth-child(2),
.table-variance-summary td:nth-child(4),
.table-variance-summary td:nth-child(6) {
  padding-right: 3em;
}
.table-variance-summary svg {
  font-size: 1rem;
}
@media (min-width: 768px) {
  /*
  #property-details {
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
  }
  */
  /*
  #property-details-content {
    width: 30%;
    max-width: 400px;
    border-left: 1px solid #ddd;
  }
  */
  #property-details-widgets {
    flex-grow: 1;
  }
  .widget-amounts .icon-dollar {
    font-size: 3rem;
  }
}
@media (min-width: 1280px) {
}

.mainPropertiesContainer {
  /*
  height: 100%;*/
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-flow: column nowrap;
}
.mainPropertiesContainer > div {
  flex-grow: 1;
  height: auto;
}
.mainPropertiesContainer > div:last-child {
  flex-grow: 100000; /* some large number */
  display: flex;
  height: auto;
}
.mainTopPropertyInfoContainer {
  display: flex;
  align-self: flex-end;
  align-items: stretch;
  flex-flow: row nowrap;
  width: 100%;
}
.property-detail-icon {
  vertical-align: text-bottom;
}
.mainPropertiesContainer .tab-pane,
.mainPropertiesContainer .portfolios-pane {
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
  padding: 0;
  width: 100%;
  height: auto !important;
}
.mainPropertiesContainer .tab-pane > div {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
.mainPropertiesContainer .tab-pane > div:last-child,
.mainPropertiesContainer .tab-pane > .tab-content,
.mainPropertiesContainer .portfolios-pane > .tab-content {
  flex-grow: 100000; /* some large number */
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: auto !important;
}
.mainPropertiesContainer .standard-content {
  flex-grow: 1;
  display: flex;
  height: auto !important;
  width: 100%;
  flex-flow: column nowrap;
}
.mainPropertiesContainer .table-standard-toggle-container {
  flex-grow: 1;
  display: flex;
  width: 100%;
}
.property-action-bar {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding: 18px 14px 6px 14px;
  background-color: var(--neutral-grey-50);
}
.property-action-bar.property-action-bar-list {
  padding: 0 14px 10px 14px;
}
.property-action-bar.property-action-bar-sub {
  padding-top: 0;
}
.property-action-bar .MuiGrid-container .MuiGrid-item {
  padding: 6px;
}
.property-action-bar .MuiGrid-container .MuiGrid-item button:disabled {
  background-color: #066cfa73;
  color: white;
}
.accordion-outer-propertyStackingPlan {
  flex-grow: 100000; /* some large number */
}
@media only screen and (min-width: 600px) {
  .mainTopPropertyInfoContainer {
    width: 30%;
    max-width: 400px;
  }
}

.tag {
  display: inline-block;
  padding: 0 8px 4px 8px;
  background: var(--neutral-grey-150);
  border-radius: 6px;
}

.parent-element {
  width: 100%;
  height: 100%;
  position: relative;
}

.top-element,
.bottom-element {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.top-element {
  pointer-events: none;
  opacity: 0.3;
}

.bottom-element {
  top: 30rem;
  padding: 0 22rem 0 29rem;
  z-index: 10;
  align-items: center;
}

.ag-theme-alpine .ag-root .ag-cell.loan-status-inactive {
  color: var(--neutral-grey-300);
}
.grid-chip {
  padding: 4px 8px;
  height: auto;
  background-color: var(--neutral-grey-150);
  border-radius: 6px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}
.loan-status-inactive .grid-chip {
  background-color: var(--neutral-grey-100);
  color: var(--neutral-grey-300);
}
.grid-chip .MuiChip-label {
  padding: 0;
}
.MuiStepLabel-label.Mui-disabled .MuiTypography-root {
  color: var(--primary-dark-blue);
}
.MuiStepLabel-root.Mui-disabled svg {
  fill: var(--neutral-grey-300);
  color: var(--neutral-grey-300);
}
/*
.MuiStepLabel-root svg.MuiStepIcon-root.Mui-completed {
  fill: rgba(6, 108, 250, 0.16);
  color: rgba(6, 108, 250, 0.16);
}
*/
.MuiStepConnector-line {
  border-color: var(--neutral-grey-300);
  border-width: 2px;
}