:root {
  --primary-blue: #066cfa;
  --primary-light-blue: #3294f1;
  --primary-dark-blue: #021a3c;
  --neutral-grey-800: #253c52;
  --neutral-grey-700: #344f67;
  --neutral-grey-600: #496680;
  --neutral-grey-500: #637e97;
  --neutral-grey-400: #8298ac;
  --neutral-grey-300: #a0b4c7;
  --neutral-grey-200: #bdccdc;
  --neutral-grey-150: #dfe7f1;
  --neutral-grey-100: #ecf1f7;
  --neutral-grey-50: #f5f8fb;
  --neutral-grey-25: #f9fafc;
  --neutral-black: #0a1a27;
  --neutral-white: #fff;
  --secondary-orange: #ff7646;
  --secondary-brown: #cc7c00;
  --semantic-red: #f43027;
  --semantic-green: #25a567;
  --semantic-yellow: #fec22e;
  --link-hover: #1653c5;
}

html, body, #root, #root>main/*, #root>main>div*/ {
  height: 100%;
}
#root {
  display: flex;
}
.MuiTypography-root {
  word-wrap: break-word; /* All browsers since IE 5.5+ */
  overflow-wrap: break-word; /* CSS3 */
}
/*
#root>main {
  height: calc(100% - 64px);
}
*/
@media only screen and (min-width: 600px) {
  /*
  #root>main {
    height: calc(100% - 65px);
  }
  */
}
body {
  margin: 0;
  /*
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}

a.MuiTypography-root {
  text-decoration-color: inherit;
}

/* breadcrumb back link */
li.MuiBreadcrumbs-li button:hover {
  background: transparent;
  color: var(--primary-blue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  width: 100%;
}

/* app bar */
.appHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 13px 20px;
  background-color: white;
}
.main-nav {
  flex-grow: 1;
}
.nav-account-summary {
  justify-content: flex-start;
}
.nav-account-container .MuiListItemIcon-root {
  margin-right: 8px;
  min-width: auto;
}
#nav-account-sub-container {
  margin-top: 12px;
  padding: 8px;
  background-color: var(--neutral-white);
  border-radius: 6px;
  border: solid 1px var(--neutral-grey-200);
  box-shadow: 0 2px 2px 0 rgb(46 57 72 / 6%);
  color: var(--primary-dark-blue);
}
#nav-account-sub-container .MuiListItemIcon-root {
  margin-right: 8px;  
}
.nav-account-list-item {
  padding: 4px 8px;
  border-radius: 6px;
}
#nav-account-sub-container .nav-account-list-item {
  padding: 6px 12px 6px 8px;
}
.nav-account-list-item:active {
  background-color: rgba(6, 108, 250, 0.12);
  color: var(--primary-blue);
}
.nav-account-list-item:hover {
  background-color: transparent;
  color: var(--primary-blue);
}
.nav-account-list-item svg {
  width: 20px;
  height: 20px;
  fill: var(--neutral-grey-500);
  color: var(--primary-dark-blue);
}
.nav-account-list-item:hover svg {
  fill: var(--primary-blue);
  color: var(--primary-blue);
}
/*
#nav-account-sub-container {
  position: absolute;
  right: 0;
}
#nav-account-sub-container span {
  color: #fff;
}
.nav-account-container .nav-account-list-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
*/
@media only screen and (min-width: 600px) {
  /*
  .main-nav + .nav-account-container {
    padding-bottom: 2rem;
  }
  */
}

main {
  /*
  margin: 60px 0 0 57px;
  margin: 0 0 0 57px;
  */
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.mainContainer {
  width: 100%;
}
.mainContainer {
  display: flex;
  /*
  align-items: stretch;
  justify-content: flex-start;
  */
  flex-flow: column nowrap;
}
/*
.mainContainer > div {
  flex-grow: 1;
  height: auto;
}
*/
.mainContainer > div:last-child {
  flex-grow: 100000; /* some really large number */
  display: flex;
  height: auto;
}
.ag-theme-alpine-container-override {
  display: flex;
  width: 100%;
}
.ag-theme-alpine-container-override > div {
  height: auto !important;
  display: flex;
  flex-grow: 1;
}
.ag-theme-alpine-container-override > div > div {
  width: 100%;
}
@media only screen and (min-width: 600px) {
  /*
  main {
    margin-top: 64px;
  }
  */
}



/* datepicker */
.datepicker {
  padding: 0 1px;
  height: 30px;
  border-width: 0 0 1px 0;
}
/*
.datepicker-outer-container {
  padding: 6px;
}
*/
.label-datepicker {
  color: var(--neutral-grey-600);
}
.label-datepicker-container, .datepicker-container {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0;
  padding: 8px 8px 4px 12px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(46, 57, 72, 0.06);
  border: solid 1px var(--neutral-grey-200);
  background-color: var(--neutral-white);
}
.property-action-bar .datepicker-container {
  width: 220px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper input, .datepicker-input, .react-datepicker-input {
  padding: 0;
  width: 100%;
  border-width: 0;
  font-size: 15px;
  font-weight: normal;
  color: var(--primary-dark-blue);
}
.right-text {
  text-align: right;
}
.react-datepicker-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.datepicker-container.daterange-container .react-datepicker__input-container {
  max-width: 75px;
}
/*
.icon-datepicker, .datepicker-icon {
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
  */
.icon-datepicker svg, .datepicker-icon svg {
  fill: var(--neutral-grey-600);
  width: 20px;
  height: 20px;
}
.icon-datepicker-dash {
  padding: 0px 5px;
}
.react-datepicker-icon svg {
  margin-top: -1px;
  margin-bottom: 1px;
  fill: var(--neutral-grey-500);
  width: 20px;
  height: 20px;
}
/*
.label-datepicker-container .react-datepicker-wrapper {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
*/
.react-datepicker-popper {
  min-width: 200px;
}
.react-datepicker-popper .react-datepicker {
  padding: 16px 0 12px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(46, 57, 72, 0.06);
  border: solid 1px var(--neutral-grey-200);
  background-color: var(--neutral-white);
  width: 100%;
}
.react-datepicker .react-datepicker__navigation--next {
  margin-top: 15px;
  right: 20px;
}
.react-datepicker .react-datepicker__navigation--previous {
  margin-top: 15px;
  left: 20px;

}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 5rem !important;
}
button.react-datepicker__navigation:hover {
  background: transparent;
}
.react-datepicker .react-datepicker__day, .react-datepicker .react-datepicker__month-text, .react-datepicker .react-datepicker__quarter-text, .react-datepicker .react-datepicker__year-text {
  padding: 7px 9px;
  background: transparent;
  border-radius: 100px;
  font-size: 15px;
  font-weight: normal;
  color: var(--neutral-grey-600);
}
.react-datepicker .react-datepicker__day.react-datepicker__day--selected, .react-datepicker .react-datepicker__month-text.react-datepicker__month--selected, .react-datepicker .react-datepicker__quarter-text.react-datepicker__quarter--selected, .react-datepicker .react-datepicker__year-text.react-datepicker__year-text--selected {
  background-color: rgba(6, 108, 250, 0.12);
  color: var(--primary-blue);
}
.react-datepicker .react-datepicker__day:hover, .react-datepicker .react-datepicker__month-text:hover, .react-datepicker .react-datepicker__quarter-text:hover, .react-datepicker .react-datepicker__year-text:hover, 
.react-datepicker .react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: transparent;
  border-radius: 100px;
  color: var(--primary-blue);
}
.react-datepicker .react-datepicker__day--disabled, .react-datepicker .react-datepicker__quarter-text--disabled, .react-datepicker .react-datepicker__year-text--disabled, .react-datepicker .react-datepicker__month--disabled, .react-datepicker .react-datepicker__quarter--disabled {
  background: transparent;
  color: var(--neutral-grey-300);
}
.react-datepicker .react-datepicker__year-wrapper {
  max-width: 205px;
}
.react-datepicker .react-datepicker__header {
  background-color: transparent;
  border-bottom: 0;
  color: var(--primary-dark-blue);
  font-size: 16px;
  font-weight: 600;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker .react-datepicker__triangle {
  display: none;
  border-bottom-color: #fff;
}

/* comparison */
.compare-negative {
  color: #F1556F;
}
.compare-positive {
  color: #43A047;
}




/* modal / dialog */
.MuiDialog-paper {
  padding: 24px 34px;
}
.MuiDialog-paper .MuiDialogContent-root {
  padding: 8px 0;
}
.MuiDialog-paper #investment-details-edit + .MuiDialogContent-root {
  margin-bottom: 15px;
  padding: 8px 2px 24px 2px;
  border-bottom: 1px solid var(--neutral-grey-150);
}
.MuiDialog-paper .MuiDialogTitle-root {
  padding: 0;
}
.MuiDialog-paper .MuiDialogActions-root {
  padding: 8px 0;
}
.MuiDialog-paper .MuiTypography-body1 {
  padding-top: 20px;
  padding-bottom: 16px;
  font-size: 1.2rem;
  font-weight: medium;
  color: #727272;
}
.MuiDialog-paper .MuiDialogContent-root > .MuiFormControl-root {
  margin: 5px 0;
}
.MuiDialog-paper .MuiInputBase-input, .MuiDialog-paper textarea {
  padding: 7px 6px 7px 12px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgb(46 57 72 / 6%);
  border: solid 1px var(--neutral-grey-200);
  background-color: var(--neutral-white);
  color: var(--primary-dark-blue);
}
.MuiDialog-paper .MuiInputBase-input + fieldset {
  border: 0;
}
.MuiDialog-paper .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
  padding: 0;
}
.MuiDialog-paper .MuiSelect-outlined {
  padding: 16px 16px 15px 16px;
}
.MuiDialog-paper > .MuiDialogContent-root > .MuiGrid-spacing-xs-1, .MuiDialog-paper > div[role=tabpanel] > .MuiBox-root > .MuiGrid-spacing-xs-1 {
  width: auto;
  margin: 0;
}
.icon-dialog-close {
  float: right;
  padding: 0;
}
.icon-dialog-close svg {
  fill: var(--primary-dark-blue);
  color: var(--primary-dark-blue);
}
.dialog-view-notes .MuiDialog-container > div {
  padding: 0;
  max-height: 600px;
}
@media screen and (min-width: 600px) {
  .dialog-view-notes .MuiDialog-container > div {
    width: 500px;
  }
}
.dialog-view-notes .MuiDialogContent-root {
  padding: 0;
  overflow: auto;
  /* show shadow when scrolling */
  background:
    /* Top Shadow covers */
    linear-gradient(white 20%, rgba(255,255,255,0)),
    linear-gradient(rgba(255,255,255,0), white 80%) 0 100%,
    /* Top Shadow */
    linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,0)) 0 0;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}
.dialog-view-notes .MuiDialogTitle-root {
  padding: 24px 34px 0 34px;
}
.dialog-view-notes .MuiListItem-root {
  padding: 8px 34px 16px 34px;
}
.dialog-view-notes .MuiDialogActions-root {
  padding: 8px 34px 32px 34px;
}
.icon-dialog-actions svg {
  width: 20px;
  height: 20px;
  fill: var(--primary-dark-blue);
  color: var(--primary-dark-blue);
}
.icon-dialog-actions:hover {
  background-color: var(--neutral-grey-150);
}
.icon-dialog-actions:hover svg {
  fill: var(--primary-blue);
  color: var(--primary-blue);
}
.icon-add svg {
    width: 16px;
    height: 16px;
}

/* portfolios */
#main-bar.portfolios-list-search-main-bar {
  background-color: #fff;
}

/* dashboard */
#dashboard-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  height: auto !important;
  background-color: var(--neutral-grey-50)
}
#dashboard-container > div {
  display: flex;
  align-items: stretch;
  padding: 15px 20px;
  height: auto !important;
  /* border-bottom: 1px solid rgba(224, 224, 224, 1); */
}
#dashboard-container > div:last-child {
  border-bottom: 0;
}
#dashboard-container .action-bar {
  padding-bottom: 0;
}
#dashboard-container .dashboard-grid-container {
  margin: 0;
  padding: 0px 10px;
  width: calc(100% - 20px);
  background: transparent;
  height: 100%!important
}
.dashboard-grid-container > div {
  overflow: hidden !important;
  background: #fff;
  /* border: 1px solid #ccc; */
}
.dashboard-grid-container h4 {
  padding-right: 16px;
  padding-left: 16px;
}
.action-bar button {
  margin-right: 10px;
  margin-bottom: 10px;
}
.dashboardFilters {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
}
.dashboard-grid-container > div > div {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: space-between;
  border: solid 1px var(--neutral-grey-150);
  border-radius: 6px;
}
.dashboard-grid-container > div > div > hr {
  display: none;
}
.dashboard-grid-container h6 {
  margin: 1em 0 0 0;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 14px;
  color: var(--primary-dark-blue);
}
.dashboard-grid-container > div > div > div > span {
  display: block;
  margin: 1em 0 0 0;
  padding: 0 16px;
  font-size: 14px;
  color: var(--neutral-grey-600);
}
.dashboard-grid-container > div > div > *:first-child, .dashboard-grid-container > div > div > *:nth-child(2), .dashboard-grid-container > div > div > *:last-child {
  width: 100%;
}
.dashboard-grid-container > div > div > hr:nth-child(2) {
  margin-bottom: 0.5em;
  width: calc(100% - 32px);
}

.add-widget-container {
  padding: 1em;
}
.add-widget-container .add-widget-close-icon {
  margin-right: 0;
  padding: 0;
  width: 40px;
}

/* tabs */
.mainTopTab {
  font-size: 15px;
  font-weight: 600;
  color: var(--neutral-grey-600);
}
button.mainTopTab:hover {
  background-color: inherit;
}

/* summary */
.summary-drawer-action button {
  padding: 8px 20px 8px 8px;
  box-shadow: 0 4px 8px 0 rgba(46, 57, 72, 0.16);
  background-color: var(--primary-dark-blue);
  border-radius: 100px;
  color: var(--neutral-white);
}
.summary-drawer-action button:hover {
  background-color: var(--neutral-grey-700);
}
.summary-drawer-action button svg {
  color: var(--neutral-white);
}

.summary-property-image {
  margin-bottom: 20px;
}
.summary-property-address {
  display: flex;
  padding: 0 24px 10px 24px;
}
.summary-property-address > div:first-child {
  padding-top: 4px;
}
.summary-property-address svg {
  width: 16px;
  height: 16px;
  color: var(--neutral-grey-400);
}
.summary-property-address .MuiTypography-body2 {
  color: var(--neutral-grey-600);
}
.summary-table-container {
  padding: 0 21px 20px 21px;
}
.summary-table-container table {
  border-bottom: 1px solid #cfe2fb;
  border-radius: 4px;
}
.summary-table-container thead th {
  padding: 0.2em 0;
  background: var(--neutral-white);
  text-align: center;
  color: var(--neutral-grey-600);
}
.summary-table-container th, .summary-table-container td {
  padding: 12px 16px;
  border: 0;
  line-height: 1.2;
}
.summary-table-container td {
  border: 1px solid #cfe2fb;
  border-width: 1px 1px 0 0;
}
.summary-table-container td:first-child {
  border-left: 1px solid #cfe2fb;
  color: var(--neutral-grey-600);
}
.summary-table-container td:nth-child(2) {
  background-color: rgba(6, 108, 250, 0.12);
  color: var(--primary-blue);
}
.summary-table-container td:last-child {
  background-color: var(--neutral-grey-50);
  color: var(--primary-dark-blue);
}
.summary-table-container .icon-tooltip {
  margin-top: -8px;
  padding: 8px;
}
.summary-table-container .icon-tooltip:hover {
  background-color: transparent;
}
.summary-table-container .icon-tooltip svg {
  fill: #2F6DD3;
  font-size: 1.2rem;
}
/*
.mainTopTab[aria-selected='true'] {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}*/

.grid-last-cell-placeholder {
  width: 100%;
}


.MuiButton-outlined.MuiButton-outlinedPrimary {
  border: 1px solid var(--neutral-grey-200);
  color: var(--primary-dark-blue);
}



/* inputs */
.form-control {
  display: block;
  width: 100%;
}
.form-control label {
  display: block;
  margin-top: 16px;
  padding-bottom: 4px;
}
.form-control-field {
  width: 100%;
  border-radius: 6px;
}
.form-control-field .MuiSelect-select {
  padding: 7px 6px 8px 12px;
}
.form-control-field.form-control-textarea .MuiOutlinedInput-root {
  padding: 0;
  border: 0;
}
.form-control-field.form-control-textarea.form-control-textarea-notes {
  padding: 4px;
}
.form-control-field.form-control-textarea.form-control-textarea-notes .MuiInputBase-root {
  border-radius: 6px;
  border: solid 1px var(--neutral-grey-200);
  background-color: var(--neutral-grey-50);
}
.form-control-field.form-control-textarea.form-control-textarea-notes .MuiInputBase-root:hover {
  box-shadow: 0 0 0 3px rgba(6, 108, 250, 0.16);
}
.form-control-field.form-control-textarea.form-control-textarea-notes .MuiInputBase-root.Mui-focused {
  border: solid 1px var(--primary-blue);
  /*outline: solid 1px var(--primary-blue) !important;*/
  box-shadow: 0 0 0 3px rgba(6, 108, 250, 0.16);
}
.form-control-field.form-control-textarea.form-control-textarea-notes textarea:first-child {
  padding: 12px;
  width: 400px;
  background-color: transparent;
  border: 0;
  box-shadow: 0;
}
.form-control-field.form-control-textarea.form-control-textarea-notes fieldset {
  border: 0;
}
.form-control-field.form-control-datepicker {
  /*
  padding: 7px 6px 7px 12px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgb(46 57 72 / 6%);
  border: solid 1px var(--neutral-grey-200);
  */
  background-color: var(--neutral-white);
  color: var(--primary-dark-blue);
}
.form-control-field.form-control-datepicker .MuiInputBase-input {
  border: 0;
  box-shadow: none;
}
.input-label {
  display: block;
  padding-bottom: 4px;
  color: var(--neutral-grey-600);
}
.input-field {
  padding: 7px 6px 7px 12px;
  width: 190px;
  max-width: 190px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgb(46 57 72 / 6%);
  border: solid 1px var(--neutral-grey-200);
  background-color: var(--neutral-white);
  color: var(--primary-dark-blue);
}
.input-field.select-dropdown {
  width: 190px;
}
svg.MuiSelect-icon {
  top: calc(50% - 0.65em);
}
svg.MuiSelect-icon, svg.MuiSelect-icon path {
  fill: var(--neutral-grey-500);
}
.input-field > div {
  padding: 0;
  border: 0;
  font-size: 15px;
  font-weight: normal;
}
.input-field > fieldset {
  border: 0;
}
/*
.input-field .MuiSelect-select {
  padding: 7px 6px 7px 12px;
  border: 1px solid var(--neutral-grey-200);
  box-shadow: 0 2px 2px 0 rgb(46 57 72 / 6%);
  color: var(--primary-dark-blue);
}
*/
.input-field:hover {
  box-shadow: 0 0 0 3px rgba(6, 108, 250, 0.16);
}
.input-field.Mui-focused {
  border: 1px solid var(--neutral-white);
  border-radius: 6px;
  outline: solid 1px var(--primary-blue) !important;
  box-shadow: 0 0 0 3px rgba(6, 108, 250, 0.16);
}
.input-field.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 0;
}
/* switch */
.input-switch {
  padding: 0;
  height: 20px;
  width: 36px;
  border-radius: 10px;
}
.input-switch .MuiSwitch-switchBase {
  padding: 0;
  background: transparent;
}
.input-switch .MuiSwitch-switchBase.Mui-checked {
  -webkit-transform: translateX(15px);
  -moz-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
  color: var(--neutral-white);
}
.input-switch .MuiSwitch-switchBase + .MuiSwitch-track {
  background-color: var(--neutral-grey-500);
  opacity: 1;
}
.input-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--primary-blue);
}
.input-switch .MuiSwitch-thumb {
  margin: 1px;
  height: 18px;
  width: 18px;
}
/* dropdowns */
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root {
  margin-top: 16px;
  margin-left: -4px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgb(46 57 72 / 6%);
  border: solid 1px var(--neutral-grey-200);
  background-color: var(--neutral-white);
}
.MuiMenu-list[aria-labelledby="grid-view-label"] {
  width: 190px;
}
#account-menu .MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root {
  width: auto;
}
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root .MuiMenu-list {
  padding: 8px 0;
  min-width: 190px;
}
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root .MuiMenuItem-root {
  margin: 1px 8px;
  padding: 8px 8px 8px 12px;
  color: var(--primary-dark-blue);
}
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root .MuiMenuItem-root:hover {
  border-radius: 6px;
  background-color: var(--neutral-white);
  color: var(--primary-blue);
}
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root .MuiMenuItem-root:hover * {
  color: var(--primary-blue);
}
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root .MuiMenuItem-root.Mui-selected {
  border-radius: 6px;
  background-color: rgba(6, 108, 250, 0.12);
  color: var(--primary-blue);
}
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root .MuiCheckbox-root {
  margin-right: 8px;
  padding: 0;
}
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root .MuiCheckbox-root svg {
  width: 16px;
  height: 16px;
}
.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPaper-root hr {
  height: 1px;
  background: var(--neutral-grey-200);
  border: 0;
}
/* end dropdowns */

.select-dropdown-menu-container {
  margin-left: -3px;
  background: transparent;
  box-shadow: none;
}
.select-dropdown-menu-container > ul {
  margin-top: 15px;
  padding: 8px;
  width: 180px;
  max-width: 180px;
  background: var(--neutral-white);
  border: 1px solid var(--neutral-grey-200);
  border-radius: 6px;
}
.select-dropdown-menu-container > ul > li {
  padding: 8px 12px;
  border-radius: 6px;
  color: var(--primary-dark-blue);
  font-weight: 400;
}
.select-dropdown-menu-container > ul > li.Mui-selected {
  background: rgba(6, 108, 250, 0.12);
  color: var(--primary-blue);
}

/* buttons */
.btn-primary {
  padding: 6px 12px;
  background-color: var(--primary-dark-blue);
  border-radius: 6px;
  color: var(--neutral-white);
}
.btn-primary:hover, .btn-primary:focus {
  background-color: var(--neutral-grey-700);
  box-shadow: 0 2px 2px 0 rgba(46, 57, 72, 0.02);
}
.btn-primary:disabled {
  background-color: var(--neutral-grey-300);
  color: var(--neutral-white);
}
.btn-secondary {
  padding: 4px 16px;
  background-color: var(--neutral-white);
  color: var(--primary-dark-blue);
  border: 1px solid var(--neutral-grey-200);
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(46, 57, 72, 0.02);
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: var(--neutral-white);
  color: var(--primary-blue);
}
.btn-secondary:disabled {
  background-color: var(--neutral-white);
  color: var(--neutral-grey-300);
}
.btn-tertiary {
  padding: 5px 12px;
  background-color: var(--primary-blue);
  border-radius: 6px;
  color: var(--neutral-white);
}
.btn-tertiary:hover, .btn-tertiary:focus {
  background-color: var(--link-hover);
  box-shadow: 0 2px 2px 0 rgba(46, 57, 72, 0.02);
}
.btn-tertiary:disabled {
  background-color: rgba(6, 108, 250, 0.32);
  color: var(--neutral-white);
}
.btn-primary svg, .btn-secondary svg, .btn-tertiary svg {
  margin-right: 4px;
  width: 16px;
}
.btn-primary svg, .btn-tertiary svg {
  fill: var(--neutral-white);
}
.btn-primary:hover svg, .btn-primary:focus svg, .btn-primary:disabled svg, .btn-tertiary:hover svg, .btn-tertiary:focus svg, .btn-tertiary:disabled svg {
  fill: var(--neutral-white);
}
.btn-secondary svg {
  fill: var(--primary-dark-blue);
}
.btn-secondary:hover svg, .btn-secondary:focus svg {
  fill: var(--primary-blue);
}
.btn-secondary:disabled svg {
  fill: var(--neutral-grey-300);
}
.btn-primary.btn-dropdown svg {
  margin-right: -4px;
  margin-left: 4px;
  width: 24px;
}
.btn-link, button.MuiLink-button {
  background-color: transparent;
  color: var(--primary-blue);
}
.btn-link:hover, .btn-link:focus, button.MuiLink-button:hover, button.MuiLink-button:focus {
  background-color: transparent;
  color: var(--link-hover);
}
.btn-link:disabled {
  background-color: transparent;
  color: var(--neutral-grey-300);
}
.btn-link svg {
  margin-top: -3px;
  height: 20px;
  width: 20px;
  vertical-align: text-top;
}
.btn-delete {
  border-radius: 6px;
  box-shadow: none;
}
.btn-delete svg {
  margin-right: 4px;
  width: 16px;
  fill: var(--neutral-white);
  color: var(--neutral-white);
}
.btn-delete:hover, .btn-delete:focus {
  background-color: #ba2b2c;
  box-shadow: 0 2px 2px 0 rgba(46, 57, 72, 0.02);
  color: var(--neutral-white);
}
.btn-delete:disabled {
  background-color: rgba(244, 48, 39, 0.32);
  color: var(--neutral-white);
}
.btn-delete:hover svg, .btn-delete:focus svg, .btn-delete:disabled svg {
  fill: var(--neutral-white);
}

/* ag grid overrides */
.ag-theme-alpine .ag-root {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  letter-spacing: 0.28px;
}
.ag-theme-alpine .ag-root .ag-header {
  background-color: var(--neutral-grey-150);
}
.ag-theme-alpine .ag-root .ag-header-cell-text {
  color: var(--primary-dark-blue);
  text-transform: uppercase;
}
.ag-theme-alpine .ag-root .ag-row {
  border-color: var(--neutral-grey-150);
}
.ag-theme-alpine .ag-root .ag-row-odd {
  background-color: var(--neutral-grey-50);
}
.ag-theme-alpine .ag-root .ag-cell {
  color: var(--primary-dark-blue);
  font-size: 15px;
  font-weight: normal;
}
.ag-theme-generations-table .ag-center-cols-container {
  width: 100% !important;
}
/*
.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-top: 15px;
  padding-bottom: 15px;
}
*/
/* financials ag grid */
#property-financials-content .ag-theme-alpine .ag-root .ag-row.ag-row-group-expanded.ag-row-level-0, #portfolio-financials-content .ag-theme-alpine .ag-root .ag-row.ag-row-group-expanded {
  background-color: rgba(6, 108, 250, 0.16);
}
#property-financials-content .ag-theme-alpine .ag-root .ag-cell, #portfolio-financials-content .ag-theme-alpine .ag-root .ag-cell {
  color: var(--neutral-grey-600);
}
#property-financials-content .ag-theme-alpine .ag-root .ag-row .ag-group-value {
  font-weight: bold;
}
#property-financials-content .ag-theme-alpine .ag-root .ag-row.ag-row-level-0 .ag-group-value {
  color: var(--primary-dark-blue);
}
#portfolio-financials-content .ag-theme-alpine .ag-root .ag-row .ag-group-value {
  font-weight: bold;
  color: var(--primary-dark-blue);
}
#property-financials-content .ag-theme-alpine .ag-root .ag-row.ag-row-footer.ag-row-level-0 .ag-cell-value, #portfolio-financials-content .ag-theme-alpine .ag-root .ag-row.ag-row-footer .ag-cell-value {
  color: var(--primary-dark-blue);
}
#property-financials-content .ag-theme-alpine .ag-root .ag-row.ag-row-footer.ag-row-level--1 .ag-cell-value, #portfolio-financials-content .ag-theme-alpine .ag-root .ag-row.ag-row-footer.ag-row-level--1 .ag-cell-value {
  font-weight: bold;
}


/* ag grid icons */
.icon-document-grid-action {
  display: inline-block;
  margin-top: 2px;
  margin-right: 16px;
  padding: 1px 2px;
  border: 1px solid var(--neutral-grey-200);
  border-radius: 6px;
  line-height: 1;
}
.icon-document-grid-action:hover {
  background-color: var(--neutral-grey-100);
}
.icon-document-grid-action svg {
  fill: var(--primary-dark-blue);
  color: var(--primary-dark-blue);
  width: 16px;
  height: 16px;
}

/* rent roll */
.icon-documents {
  margin-top: 2px;
  padding: 5px 6px;
  border: 1px solid var(--neutral-grey-200);
  border-radius: 6px;
  line-height: 1;
}
.icon-documents svg {
  fill: var(--primary-dark-blue);
}
.icon-documents:hover svg {
  fill: var(--primary-blue);
}


/* settings */
.mainSettingsContainer {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-flow: column nowrap;
}
.mainSettingsContainer > div {
  flex-grow: 1;
  height: auto;
}
.mainSettingsContainer-database > div:last-child {
  flex: 1 1 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.mainSettingsContainer-database .property-action-bar {
  border-top: 1px solid #ccc;
}
.mainSettingsContainer .tab-panel-container {
  flex: 1 1 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  /*overflow-y: auto;*/
}
.mainSettingsContainer .tab-panel-container > div, .mainSettingsContainer .tab-panel-container > div > div, .mainSettingsContainer .grid-container, .mainSettingsContainer .grid-container > div {
  flex: 1 1 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.mainSettingsContainer .tab-panel-container > div[hidden] {
  display: none;
}
.mainSettingsContainer .database-panel-container {
  flex: 1 1 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  background-color: var(--neutral-grey-50);
}
.mainSettingsContainer .info-panel-container {
  flex: 1 1 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--neutral-grey-50);
  border-top: 1px solid #ccc;
}
.mainSettingsContainer .tab-pane {
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
  padding: 0;
  width: 100%;
  height: auto !important;
}
.mainSettingsContainer .tab-pane > div {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
.mainSettingsContainer .tab-pane > div:last-child,
.mainSettingsContainer .tab-pane > .tab-content {
  flex-grow: 100000; /* some large number */
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: auto !important;
}
.mainSettingsContainer .tab-content {
  flex-grow: 100000; /* some large number */
  height: auto !important;
}
.mainSettingsContainer .standard-content {
  flex-grow: 1;
  display: flex;
  height: auto !important;
  width: 100%;
  flex-flow: column nowrap;
}
.mainSettingsContainer .table-standard-toggle-container {
  flex-grow: 1;
  display: flex;
  width: 100%;
}
.listitems-checklist {
  padding: 4px 0;
}
.listitems-checklist:hover {
  background: transparent;
}
.listitems-checklist .MuiListItemIcon-root {
  margin-right: 8px;
  min-width: auto;
}
.listitems-checklist .MuiCheckbox-root {
  margin: 0 0 0 -2px;
  padding: 0;
}
.listitems-checklist .MuiCheckbox-root .MuiSvgIcon-root {
  width: 25px;
  height: 25px;
}
.grid-checkbox svg {
  width: 20px;
  height: 20px;
}

/* property list */
.property-list-name {
    display: flex;
    vertical-align: middle;
    text-decoration: none;
}
.property-list-name span {
    display: flex;
    flex-flow: column;
    vertical-align: middle;
    justify-content: center;
    line-height: 1.4;
    font-size: 15px;
    font-weight: 500;
    color: var(--neutral-grey-600);
}
.property-list-name > span > span {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-dark-blue);
}
.property-list-img {
    margin: 12px 12px 12px 0;
    width: 60px;
    height: 60px;
    border-radius: 6px;
}
.property-list-name .property-list-img-placeholder {
    display: block;
    margin: 12px 12px 12px 0;
    width: 60px;
    height: 60px;
    background: var(--neutral-grey-150);
    border-radius: 6px;
}
#property-main-content .ag-theme-alpine .ag-ltr .ag-cell {
    display: flex;
    align-items: center;
}
#property-main-content .ag-theme-alpine .ag-ltr .ag-cell.property-list-name-col {
    display: block;
}

/* overview */
.icon-overview {
  padding: 6px 6px 4px 6px;
  min-width: auto;
  background-color: rgba(6, 108, 250, 0.08);
  border-radius: 6px;
}
.icon-overview svg {
  fill: var(--primary-blue);
  color: var(--primary-blue);
}
.overview-chip {
  padding: 2px 8px 4px 8px;
  border-radius: 6px;
  background-color: var(--neutral-grey-150);
  align-items: center;
}

/* documents */
.add-document-container {
  margin: 20px;
  padding: 30px 20px 30px 20px;
  width: 100%;
  border-radius: 6px;
  border: dashed 1px var(--primary-blue);
  background-color: rgba(6, 108, 250, 0.08);
  text-align: center;
  cursor: pointer;
}
.add-document-container svg {
  fill: var(--primary-blue);
  color: var(--primary-blue);
}
.upload-document-container svg {
  width: 20px;
  height: 20px;
  fill: var(--neutral-grey-600);
  color: var(--neutral-grey-600);
}
.ag-theme-alpine.ag-grid-document .ag-root-wrapper {
  border: 0;
  border-top: 1px solid var(--neutral-grey-150);
}
.ag-theme-alpine.ag-grid-document .ag-root .ag-header {
  background-color: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-grey-150);
}
.ag-theme-alpine.ag-grid-document .ag-root .ag-header-cell-text {
  color: var(--neutral-grey-600);
}
.ag-theme-alpine.ag-grid-document .ag-root .ag-row-odd {
  background-color: var(--neutral-white);
}
.ag-theme-alpine.ag-grid-document .ag-root .ag-cell {
  color: var(--primary-dark-blue);
  font-size: 15px;
  font-weight: normal;
}
.ag-theme-generations-table .ag-center-cols-container {
  width: 100% !important;
}

/* accordions */
.accordion-container.Mui-expanded {
  background: #f8fafe;
}
.accordion-container .MuiAccordionSummary-expandIcon {
  order: -1;
}
.accordion-container-overview .MuiAccordionSummary-expandIconWrapper {
  padding: 0;
}
.icon-expand {
  line-height: 0;
}
.icon-expand svg {
  width: 32px;
  height: 32px;
  fill: var(--primary-dark-blue);
  color: var(--primary-dark-blue);
}

/* icons */
.icon-grid {
    padding-top: 5px;
    cursor: pointer;
}
.icon-grid .MuiBadge-root {
  vertical-align: baseline;
}
.icon {
  padding: 6px;
  height: 32px;
  width: 32px;
}
svg.icon {
  max-height: none;
  max-width: none;
}
.icon:hover, .icon:focus {
  background-color: var(--neutral-grey-150);
  border-radius: 50%;
  fill: var(--primary-blue);
}
.icon:disabled {
  fill: var(--neutral-grey-300);
}
.icon-delete:hover, .icon-delete:focus {
  fill: var(--semantic-red);
}



/* leasing activity */
#property-leasing-widgets {
  padding: 10px 20px;
  height: 100%;
  background-color: var(--neutral-grey-50);
}
.leasing-chip {
  display: inline-block;
  padding: 2px 8px 4px 8px;
  border-radius: 6px;
  background-color: var(--neutral-grey-150);
  align-items: center;
}
.leasing-chip-top {
  margin-right: 8px;
}
.leasing-pipeline-cell-alt {
  background-color: var(--neutral-grey-50);
}
.table-leasing-pipeline {
  border: 1px solid var(--neutral-grey-150);
  border-width: 1px 0 0 0;
}
.table-leasing-pipeline .MuiTableCell-root {
  width: 25%;
  border: 1px solid var(--neutral-grey-150);
  border-width: 0 1px 1px 0;
}
.table-leasing-pipeline .MuiTableCell-root:last-child {
  border-width: 0 0 1px 0;
}
.icon-leasing-pipeline {
  padding-top: 0;
  padding-bottom: 0;
}
.icon-leasing-pipeline svg {
  width: 16px;
  height: 16px;
}
.table-leasing-pipeline-sub .icon-leasing-pipeline p {
  padding-bottom: 0;
  font-size: 16px;
}
.table-leasing-pipeline-sub th {
  padding-bottom: 8px;
  border-top: 1px solid var(--neutral-grey-150);
  border-bottom: 0;
}
.table-leasing-pipeline-sub td {
  vertical-align: top;
  border-bottom: 1px solid var(--neutral-grey-150);
}
.table-leasing-pipeline-sub tr:last-child td {
  border-bottom: 0;
}
.table-leasing-pipeline-sub h6 {
  padding-bottom: 8px;
}
.table-leasing-pipeline-sub p {
  padding-bottom: 4px;
}
.icon-leasing-activity {
  padding: 2px 4px;
  border-radius: 4px;
}
.icon-leasing-activity svg {
  width: 12px;
  height: 12px;
  fill: var(--neutral-white);
  color: var(--neutral-white);
}
.icon-leasing-activity.icon-leasing-activity-new-leases {
  background-color: var(--semantic-green);
}
.icon-leasing-activity.icon-leasing-activity-renewal-leases {
  background-color: var(--primary-light-blue);
}
.icon-leasing-activity.icon-leasing-activity-move-outs {
  background-color: var(--neutral-grey-500);
}
.icon-leasing-activity.icon-leasing-activity-move-outs svg {
  transform: rotate(-45deg);
}
.icon-leasing-activity.icon-leasing-activity-lease-expiration-schedule {
  background-color: #f9b101;
}
.icon-leasing-activity.icon-leasing-activity-lease-expiration-schedule svg {
  transform: rotate(-90deg);
}
.table-leasing-activity th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: rgba(50, 148, 241, 0.24);
  border: 1px solid var(--neutral-grey-150);
  border-width: 1px 0 0 0;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-dark-blue);
  text-transform: uppercase;
  line-height: 1.4;
}
.table-leasing-activity td {
  padding-top: 11px;
  padding-bottom: 11px;
  border: 1px solid var(--neutral-grey-150);
  border-width: 1px 0 0 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--primary-dark-blue);
}
.table-leasing-activity tr:nth-child(even) td {
  background-color: var(--neutral-grey-50);
}

/* accordions */
.accordion-container.Mui-expanded {
  background: #f8fafe;
}
.accordion-container .MuiAccordionSummary-expandIcon {
  order: -1;
}
.accordion-container-overview .MuiAccordionSummary-expandIconWrapper {
  padding: 0;
}
.icon-expand {
  line-height: 0;
}
.icon-expand svg {
  width: 32px;
  height: 32px;
  fill: var(--primary-dark-blue);
  color: var(--primary-dark-blue);
}
.expand-all-container {
  text-align: right;
}
.expand-all-container button {
  padding: 0;
  text-decoration: underline;
}
.expand-all-container svg {
  width: 16px;
  height: 16px;
  fill: var(--primary-blue);
  color: var(--primary-blue);
}

/* tooltip */
.MuiTooltip-tooltip {
  background-color: var(--neutral-grey-700);
}

.dashboard-message {
  padding-top: 0px !important;
}