/*body {
  background-color: #f2f2f3;
}*/
.navbar.bg-custom {
  background-color: #f2f2f3;
}
.body-container {
  padding-top: 30px;
  padding-bottom: 30px;
}
footer {
  padding: 1em;
  background: #fff;
}
.welcome-page li {
  list-style: none;
  padding: 4px;
}
.logged-out-page iframe {
  display: none;
  width: 0;
  height: 0;
}
.grants-page .card {
  margin-top: 20px;
  border-bottom: 1px solid lightgray;
}
.grants-page .card .card-title {
  font-size: 120%;
  font-weight: bold;
}
.grants-page .card .card-title img {
  width: 100px;
  height: 100px;
}
.grants-page .card label {
  font-weight: bold;
}

/* form */
.form-group {
  position: relative; /* need to explicitly state this for child absolutes to confine within this container */
}
.form-check-lg {
  margin: 0.5em 0;
  padding: 0;
}
.form-check-lg .form-check-input {
  position: absolute;
  top: 1.4em;
  left: 1em;
  margin-left: auto;
  margin-right: 0;
  padding: 1em;
  cursor: pointer;
  z-index: 1;
}
.form-check-lg .form-check-label {
  position: relative;
  padding: 1em 1em 1em 2.5em;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25em;
  cursor: pointer;
  transition: background-color 0.2s;
}
.form-check-lg .form-check-input:checked + .form-check-label {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
  transition: background-color 0.2s;
}
label.label-custom {
  display: none;
  position: absolute;
  top: 5px;
  font-size: 0.75em;
  color: #aaa;
  padding-left: 1em;
}
[type="text"],
[type="password"],
[type="number"],
[type="email"],
[type="tel"],
[type="url"],
.select-custom {
  transition: padding-top 0.2s;
  height: auto;
}
.select-custom {
  padding-left: 0.5em;
}
[type="text"].input-has-value,
[type="password"].input-has-value,
[type="number"].input-has-value,
[type="email"].input-has-value,
[type="tel"].input-has-value,
[type="url"].input-has-value,
.select-custom.input-has-value {
  padding-top: 1.75em;
  transition: padding-top 0.2s;
}
@media screen and(min-width: 768px) {
}

/* general */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
  /*-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;*/
}
::-webkit-scrollbar-thumb {
  background-color: var(--neutral-grey-300);
  /*-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);*/
  border-radius: 4px;
}
.btn-text {
  color: inherit;
  text-decoration: none;
}

/* app */

/* aside */
#app-body-container {
  margin: 0;
}
#aside,
#main {
  padding: 57px 0px;
  height: 100vh;
}
#aside-toggler-container{
  padding: 0;
  height: 100vh;
}
#main {
  background-color: #fff;
}
#aside .card-header {
  cursor: pointer;
}
.data-menu-selected {
  background-color: #666;
  color: #fff;
}
#aside .list-group-item {
  padding: 0;
}

#accordionContainer {
  width: 100%;
}
#accordionContainer .accordion {
  width: 100%;
}
.aside-toggler {
  width: 100%;
  height: 100%;
  border: 0;
}
.list-group-item a {
  display: block;
  padding: 0.75rem 1.25rem;
}
.list-group-item.active a {
  color: #fff;
}
#asideNavBar {
  padding: 0;
}
#leftMenuToogle{
  font-size: small;
}




/* custom styles for material UI */
#main-top-container {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-flow: row-reverse wrap;
}
@media screen and(min-width: 601px) { /* material UI sm breakpoint */
  #main-top-property-info-container {
    width: 25%;
  }
  #main-breadcrumb, .main-tabs {
    width: 75%;
  }
  .tab-pane {
    width: 100%;
  }
}

/*
.MuiTab-root.Mui-selected {
  color: #1976d2;
}
*/

.expireAlertContainer a svg {
  margin-left: 5px;
  vertical-align: text-bottom;
}